import * as React from "react";
import { FullscreenBodyRoot } from "../../layout/BodyRoot/BodyRoot";
import { NavigationBar } from "../../molecules/NavigationBar/NavigationBar";
import { SEO } from "../../molecules/SEO/SEO";

interface IProps {
    readonly title: string;
    readonly description: string;
    readonly canonical: string;
}

export const StandardPageTemplate: React.FC<React.PropsWithChildren<IProps>> = ({ children, title, description, canonical }) => {
    return (
        <React.Fragment>
            <SEO title={title} description={description} relativeCanonicalUrl={canonical} />
            <FullscreenBodyRoot>{children}</FullscreenBodyRoot>
            <NavigationBar />
        </React.Fragment>
    );
};
