import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import BomberlandPreview from "./bomberland-preview.gif";

export const CoderOneProject: React.FC = () => {
    return (
        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
            <div className="relative isolate overflow-hidden">
                <svg
                    aria-hidden="true"
                    className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                >
                    <defs>
                        <pattern
                            x="50%"
                            y={-1}
                            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                            width={200}
                            height={200}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <rect fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" width="100%" height="100%" strokeWidth={0} />
                </svg>
                <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                        <StaticImage width={44} height={44} alt="CoderOne logo" src="./coderone-logo.png" className="h-11 rounded-full" />

                        <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Coder One</h1>

                        <span className=" mt-4 inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                            2021 - 2023
                        </span>

                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Coder One is an annual AI programming tournament for engineers, data scientists, researchers, students, and
                            hobbyist programmers.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            The goal is to program an AI player to compete in a multiplayer game against other teams. Participants are given
                            tutorials and resources for getting started. Over a two week submission period, participants will apply creative
                            problem solving skills and algorithms to improve their AI players.
                        </p>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a
                                href="https://gocoder.one/?s=thegalah-projects"
                                target="_blank"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                See Website
                            </a>
                            {/* <a
                            href="https://gocoder.one/?s=thegalah-projects"
                            target="_blank"
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            Learn more <span aria-hidden="true">→</span>
                        </a> */}
                        </div>
                    </div>
                    <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                            <div className="-m-2 lg:-m-4 lg:rounded-2xl lg:p-4">
                                <img alt="App screenshot" src={BomberlandPreview} className="rounded-md" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
