import * as React from "react";
import { useTranslation } from "react-i18next";
import { GalahRoute } from "../../../services/Constants/GalahRoute";
import { StandardPageTemplate } from "../../templates/StandardPageTemplate/StandardPageTemplate";
import { ProjectsSection } from "../../molecules/ProjectsSection/ProjectsSection";
import { CoderOneProject } from "./CoderoneProject/CoderOneProject";
import { HeadbotProject } from "./HeadbotProject/HeadbotProject";

export const ProjectsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("projectsPage.description");
    const title = t("projectsPage.title");
    return (
        <StandardPageTemplate title={title} description={description} canonical={GalahRoute.Projects}>
            <ProjectsSection />
            <HeadbotProject />
            <CoderOneProject />
        </StandardPageTemplate>
    );
};
